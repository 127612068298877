import { render, staticRenderFns } from "./show-note-modal.vue?vue&type=template&id=630cbcb4&scoped=true"
import script from "./show-note-modal.vue?vue&type=script&lang=js"
export * from "./show-note-modal.vue?vue&type=script&lang=js"
import style0 from "./show-note-modal.vue?vue&type=style&index=0&id=630cbcb4&prod&lang=scss&scoped=true"
import style1 from "./show-note-modal.vue?vue&type=style&index=1&id=630cbcb4&prod&lang=scss"
import style2 from "./show-note-modal.vue?vue&type=style&index=2&id=630cbcb4&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630cbcb4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {EditingHistory: require('/codebuild/output/src3271430876/src/web/components/editing-history.vue').default})
