

import moment from "moment";

export default {
    name: "ImportLog",
    filters: {
        labelImport(value) {
            switch (value) {
                case 'ABC_IMPORT':
                    return 'ABC Excel Import';
                case 'ATM_IMPORT':
                    return 'ATM Excel Import';
                case 'CALL_LOG_IMPORT':
                    return 'Call Log Mail Import';
                case 'CALL_LOG_IMPORT_CHECK':
                    return 'Call Log Mail Import Check';
                case 'READERSHIP_IMPORT':
                    return 'Readership Excel Import';
                case 'CLOSED_DEAL_IMPORT':
                    return 'Closed Deal Excel Import';
                case 'RESTRICTED_LIST_IMPORT':
                    return 'Restricted List Excel Import';
                case 'CLIENT_LIST_IMPORT':
                    return 'Client List Excel Import';
                case 'CASH_BURN_IMPORT':
                    return 'Cash Burn Excel Import';
                default:
                    return String(value)
                        .toLocaleLowerCase()
                        .replace(/_/g, ' ')
                        .replace(/\b\w/g, l => l.toUpperCase());
            }
        },
    },
    props: {
        logData: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        displayColor(color, type, dateTime) {
            switch(color) {
                case 'success':
                    return this.isSpecial(type) ? this.hoursFromNow(dateTime) < 3 : this.daysFromNow(dateTime) <= 7;
                case 'warning':
                    return this.isSpecial(type) ? this.hoursFromNow(dateTime) >= 3 && this.hoursFromNow(dateTime) < 12 :
                        this.daysFromNow(dateTime) > 7 && this.daysFromNow(dateTime) <= 14;
                case 'danger':
                    return this.isSpecial(type) ? this.hoursFromNow(dateTime) >= 12 : this.daysFromNow(dateTime) > 14;
            }
        },
        daysFromNow(date) {
            return moment().diff(date, 'days');
        },
        hoursFromNow(date) {
            return moment().diff(date, 'hours');
        },
        isSpecial(type) {
            return type === 'CALL_LOG_IMPORT_CHECK';
        },
        fromNow(date) {
            return moment(date).fromNow();
        },
    },
}
