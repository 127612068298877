import { render, staticRenderFns } from "./red-flag-modal.vue?vue&type=template&id=649b598a"
import script from "./red-flag-modal.vue?vue&type=script&lang=js"
export * from "./red-flag-modal.vue?vue&type=script&lang=js"
import style0 from "./red-flag-modal.vue?vue&type=style&index=0&id=649b598a&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src3271430876/src/web/components/loading-spinner.vue').default,SaveButton: require('/codebuild/output/src3271430876/src/web/components/save-button.vue').default})
